.tech-container{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: #F3F6F9;
    height: calc(100% + 140px);
}

.tech-inner-container{
    display: flex;
    flex: 1;
    align-self: stretch;
    height: 100%;  
}

.search-bar-input{
    width: 8vw;
    height: 45px;
    border-width: 1px;
    border-color: #C9C9C9;
    border-radius: 5px;
    font-size: 1.2rem;
}

.search-label{
    font-size: 15px;
}

.render-calls-container{
    display: flex;
    overflow: scroll;
    flex: 1;
    align-self: stretch;
    height: 100%;
    background-color: #F3F6F9;
    justify-content: center;
    align-items: center;
}

.render-calls-inner-container{
    flex: 1;
    align-self: stretch;
    background-color: transparent;
    margin: 15px 20px 0 20px;
}

.dataTables_filter{
    display: flex;
    padding-top: 10px;
    gap: 15px;
    justify-content: flex-start;
    align-items: flex-end;
}

.render-calls-top-bar{
    background-color: white;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.render-calls-top-bar>span{
    color: #7A63FF;
    font-size: 1.3rem;
    font-weight: bold;
}

.render-left-container{
    display: flex;
    width: 250px;
    background-color: white;
    height: 100%;
    padding-top: 25px;
    flex-direction: column;
}

.render-left-elemet>img{
    height: 50px;
    width: 50px;
    padding: 10px;
}

.render-left-elemet>span , .render-left-elemet>span>a{
    color: #7A63FF;
    font-size: 1.2rem;
    font-weight: bold;
}

.render-left-selected-element{
    border-left-width: 4px;
    border-left-color: #7A63FF;
    border-left-style: solid;
    height: 50px;
    display: flex;
    align-items: center;
}

.render-left-unselected-element{
    margin-left: 4px;
    height: 50px;
    display: flex;
    align-items: center;
}

.log-out-container{
    margin-left: 4px;
    height: 50px;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    cursor: pointer;
}

.log-out-container>img{
    height: 50px;
    width: 50px;
    padding: 10px;
}

.log-out-container>span{
    color: #E23D3D;
    font-size: 1.2rem;
    font-weight: bold;
  
}

.render-live-store-container{
    display: flex;
    overflow: scroll;
    flex: 1;
    align-self: stretch;
    height: 100%;
    background-color: #F3F6F9;
    justify-content: center;
    align-items: center;
}

.render-live-store-no-feature{
    flex: 1;
    display: flex;
    align-self: stretch;
    background-color: white;
    margin: 20px;
    justify-content: center;
    align-items: center;
}

.render-live-store-feature{
    flex: 1;
    align-self: stretch;
    background-color: transparent;
    margin: 15px 20px 0 20px;
}



.sparePart{
    display: inline-block;
    position: relative;
}

.sparePart img{
    width: 23px;
    height: 23px;
}

.sparePartDesc{
    display: none;
    position: absolute;
    left: 120%;
    top: 0;
    min-width: 200px;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: rgba(0, 0,0,.7);
    color: white;
}

.sparePartDesc b{
    display: block;
}

.sparePart:hover .sparePartDesc {
    display: block;
}