.render-content{
    display: flex;
    overflow: scroll;
    flex: 1;
    align-self: stretch;
    height: 100%;
    background-color: #F3F6F9;
    justify-content: center;
    align-items: center;  
}

.render-content-inner{
    flex: 2;
    align-self: stretch;
    background-color: transparent;
    margin: 32px;
}

.render-content-header{
    background-color: white;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom-width: 1px;
    border-bottom-color: #EBEBEB;
    border-bottom-style: solid;
    padding-bottom: 10px;
}

.render-content-header-box{
    display: flex;
    flex-direction: column;
}

.render-content-header-box-label{
    color: #7A63FF;
    font-size: 15px;
    font-weight: bold;
}

.render-content-header-box-value{
    color: #464A53;
    font-weight: bold;
}

.render-content-body{
    background-color: white;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.render-content-body-box-label{
    color: #B8B8B8;
    font-weight: bold;
    font-size: 14px;
  
}

.render-content-body-select-box{
    display: flex;
    flex-direction: row;
}

.render-content-body-select-box>div{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 120px;
}

.render-content-body-select-box>div>div{
    display: flex;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
}

.render-content-body-select-box>div>div>div{
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #7A63FF;
}

.render-content-body-textArea-box{
    display: flex;
    flex-direction: row;
}

.render-content-body-textArea-box textarea{
    padding: 8px;
    border-radius: 4px;
    margin-right: 16px;
    margin-top: 16px;
    height: 50%;
    width: 100%;
    border-width: 1px;
    border-color: #F0F0F0;
    outline: none;
    resize: none;
}

.render-content-body-btns{
    margin: 16px 16px 0 0;
    display: flex;
    justify-content: flex-end;
    font-size: 1rem;
    font-weight: 600;
    gap: 16px;
    text-align: center;
}

.render-content-body-btns .cancel-btn{
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
    align-self: stretch;
    color: #C9C9C9;
}

.render-content-body-btns .save-btn{
    justify-content: center;
    align-items: center;
    display: flex;
    align-self: stretch;
    color: white;
    background-color: #7A63FF;
    padding: 5px 35px;
    border-radius: 4px;
    cursor: pointer;
}

.my-messages-style{
    background-color: #7A63FF;
    margin: 5px 15px 5px 0;
    padding: 10px;
    border-radius: 10px;
    max-width: 310px;
}

.other-messages-style{
    background-color: #EBEBEB;
    margin: 5px 15px 5px 0;
    padding: 10px;
    border-radius: 10px;
    max-width: 310px;
}

.messages-container{
    flex: 1;
    align-self: stretch;
    background-color: transparent;
    margin-top: 32px;
    margin-right: 32px;
    overflow-y: scroll;
}

.messages-container-header{
    background-color: white;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom-width: 1px;
    border-bottom-color: #EBEBEB;
    border-bottom-style: solid;
    padding-bottom: 10px;
}

.messages-container-header>div{
    display: flex;
    flex-direction: column;
}

.messages-container-header>div>span{
    color: #7A63FF;
    font-size: 22px;
    font-weight: bold;
}

.messages-container-body{
    background-color: white;
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.messages-inner-container{
    display: flex;
    flex: 7;
    background-color: white;
    overflow: scroll;
    flex-direction: column;
    scrollbar-width: none;
}

.callDetail-container{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    background-color: #F3F6F9;
    height: calc(100% + 70px);
  
}

.callDetail-inner-container{
    display: flex;
    flex: 1;
    align-self: stretch;
    height: 100%;  
}