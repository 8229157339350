.meeting.wrapper {
  background: rgb(12, 43, 64);
  height: 100%;
}

video {
  max-width: unset;
}

.meeting .ag-header {
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ag-header-lead {
  font-size: 16px;
  line-height: 35px;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
}

.meeting .ag-footer {
  padding: 0 30px;
  display: flex;
  align-items: center;
}

.meeting .ag-main {
  position: relative;
}

.meeting .ag-footer :first-child {
  margin-right: 80px;
}

.header-logo {
  width: 60px;
  height: 35px;
  margin-right: 12px;
  object-fit: contain;
}

.ag-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: rgba(255, 255, 255, .1);
  border-radius: 12px;
}

::placeholder {
  color: #C9C9C9;
  opacity: 1; /* Firefox */
}

.meeting-icon-img{
  height: 50px;
  width: 50px;
  object-fit: contain;
  padding: 10px;
}

.showBadge{
  position: relative;
  width: 20px;
  left: 30px;
  top: -50px;
  height: 20px;
  border-radius: 10px;
}

.color-palette{
  z-index: 999;
  position: absolute;
  left: 65px;
  top: 140px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: row;
  box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.42);
}

.color-palette-box{
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin: 5px;
}

.render-scren-shoot{
  z-index: 1000001;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.render-scren-shoot-inner{
  display: flex;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.meeting-loader{
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.render-scren-shoot-img{
  height: 50px;
  object-fit: contain;
  width: 50px;
  padding: 10px;
}

.enter-message-box{
  display: flex;
  height: 50px;
  flex: 1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #EBEBEB;
  align-items: center;
}

.enter-message-box-top{
  display: flex;
  justify-content: center;
  align-items: center;
}

.enter-message-box-top img{
  height: 60px;
  width: 60px;
  object-fit: contain;
  padding: 10px;
  margin-top: 7px;
}

.enter-message-box-bottom{
  height: 45px;
  width: 45px;
  border-radius: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.enter-message-box-bottom img{
  margin-left: 4px;
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.render-mobile-box{
  z-index: 1000001;
  bottom: 0;
  left: 0;
  position: absolute;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.42);
}

.render-mobile-box-inner{
  background-color: #EBEBEB;
  display: flex;
  flex-direction: row;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 2px;
}

.render-mobile-box-inner-top{
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: row;
}

.render-mobile-box-inner-top span{
  font-weight: bold;
  font-size: 1.4rem;
  align-items: center;
  margin-left: 10px;
}

.render-mobile-box-inner-bottom{
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.render-mobile-box-inner-bottom-inner{
  height: 35px;
  width: 35px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.render-mobile-box-inner-bottom-inner img{
  height: 35px;
  width: 35px;
  object-fit: contain;
  padding: 10px;
}

.render-message-box{
  z-index: 1000001;
  position: absolute;
  height: 600px;
  width: 450px;
  background-color: red;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.42);
}

.render-message-box>img{
  height: 50px;
  width: 50px;
  object-fit: contain;
  padding: 10px;
}

.render-message-box-inner{
  background-color: #EBEBEB;
  display: flex;
  flex-direction: row;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 2px;
}

.render-message-box-inner-top{
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: row;
}

.render-message-box-inner-top img{
  height: 50px;
  width: 50px;
  object-fit: contain;
  padding: 10px;
}

.render-message-box-inner-top span{
  color: #7A63FF;
  font-weight: bold;
  font-size: 1.4rem;
  align-items: center;
}

.render-message-box-inner-bottom{
  height: 40px;
  width: 40px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.render-message-box-inner-bottom img{
  height: 35px;
  width: 35px;
  object-fit: contain;
  padding: 10px;
  background-color: #7A63FF;
  border-radius: 20px;
}


.show-file{
  z-index: 1000001;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.show-file-inner{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: flex-end;
  align-items: center;

}

.show-file-inner img{
  height: 35px;
  width: 35px;
  margin-right: 15px;
  background-color: rgb(223, 34, 40);
  padding: 3px;
  border-radius: 3px;
}

.meeting-messages{
  display: flex;
  flex: 7;
  background-color: white;
  overflow: scroll;
  flex-direction: column;
  scrollbar-width: none;
}

.hover-btn{
  margin-top: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  align-self: stretch;
  font-size: 1.3rem;
  font-weight: 600;
  margin-right: 16px;
  margin-left: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 4px;
  text-align: center;
}

.pin-enter{
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  background-color: rgb(245, 245, 245);
}

.render-tool-bar{
  background-color: white;
  height: 100%;
  width: 58px;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
}

.render-tool-bar-img{
  height: 50px;
  object-fit: contain;
  width: 50px;
  padding: 10px;
}

.render-btns{
  display: flex;
  align-self: stretch;
  align-items: center;
  margin-top: 16px;
  flex-direction: column;
  padding-bottom: 16px;
}

.render-btns>span{
  font-size: 1.4rem;
  color: #515151;
  font-weight: 600;
}

.meeting-textArea{
  align-self: stretch;
  padding: 8px;
  border-radius: 4px;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
  border-width: 1px;
  border-color: #F0F0F0;
  outline: none;
  resize: none;
}

.render-tech-form{
  display: flex;
  flex: 0.25;
  flex-direction: column;
  margin: 16px;
}

.render-tech-form-top{
  background-color: #7A63FF;
  display: flex;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  justify-content: center;
  align-items: center;
}

.render-tech-form-top span{
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 1.4rem;
  font-weight: bold;
}

.render-tech-form-bottom{
  display: flex;
  flex: 1;
  border-width: 0;
  background-color: white;
  flex-direction: column;
}

.render-tech-form-bottom-box{
  display: flex;
  justify-content: space-between;
  margin: 16px;
  margin-bottom: 0;
}

.render-tech-form-bottom-box-first-span{
  font-size: 1.2rem;
  color: #C9C9C9;
}

.render-tech-form-bottom-box-second-span{
  font-size: 1.2rem;
  color: #515151;
  font-weight: 600;
}

.render-rate-us{
  display: flex;
  flex-direction: column;
  margin-right: -30px;
  margin-left: -30px;
}

.render-rate-us-inner{
  display: flex;
  background-color: #FFFFFF;
  width: 100%;
  height: 80vh;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
}

.render-rate-us-inner-first-span{
  font-size: 26px;
  color: #7A63FF;
  font-weight: bold;
  margin-top: 46px;
}

.render-waiting-customer{
  display: flex;
  flex: 1;
  align-self: stretch;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.render-video-lottie{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 40vh;
}

.render-video-call{
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.render-end-call{
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.render-end-call-inner{
  width: 25%;
  height: 70vh;
  background-color: white;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.render-tech-rate{
  display: flex;
  flex: 0.25;
  flex-direction: column;
  margin: 16px;
}

.render-tech-rate-top{
  background-color: #7A63FF;
  display: flex;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  justify-content: center;
  align-items: center;
}

.render-tech-rate-bottom{
  display: flex;
  flex: 1;
  border-width: 0;
  background-color: white;
  flex-direction: column;
  align-items: center;
}

.render-tech-rate-bottom-textArea{
  padding: 8px;
  border-radius: 4px;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
  height: 50%;
  border-width: 1px;
  border-color: #F0F0F0;
  outline: none;
  resize: none;
}

.render-tech-review-end{
  display: flex;
  flex: 0.25;
  flex-direction: column;
  margin: 16px;
}

.render-tech-review-end-top{
  background-color: #7A63FF;
  display: flex;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  justify-content: center;
  align-items: center;
}

.render-tech-review-end-bottom{
  display: flex;
  flex: 1;
  border-width: 0;
  background-color: white;
  flex-direction: column;
  align-items: center;
}

.render-tech-review-end-bottom-btn{
  margin-top: 40px;
  margin-bottom: 20px;
  width: 100%;
  height: 50px;
  background-color: #7A63FF;
  border-width: 0;
  border-radius: 4px;
  color: white;
  font-size: 1.2rem;
}