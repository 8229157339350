.login-container{
    display: flex; 
    flex: 1; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    height: 100%; 
    flex-direction: row; 
}

.login-container .left{
    display: flex;
    flex: 1;
    height: 100%;
}

.login-container .left .textBox{
    position: absolute;
    bottom: 200px;
    left: 36px;
    display: flex;
    flex-direction: column;
    right: 52vw;
}

.login-container .left .textBox .title{
    color: white;
    font-size: 3.5rem;
}

.login-container .left .textBox .text{
    color: #CBCBCB;
    font-size: 1.8rem;
}

.login-container .right{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-container .rightInner{
    display: flex;
    flex-direction: column;
}

.login-input{
    padding-left: 5px;
    outline-color: #7A63FF;
    width: 20vw;
    height: 50px;
    border-width: 0px;
    background-color: #F2F2F2;
    border-radius: 4px;
    font-size: 1.2rem;
}

.login-btn{
    margin-top: 10px;
    width: 300px;
    height: 50px;
    background-color: #7A63FF;
    border-width: 0;
    border-radius: 4px;
    width: 20vw;
    color: white;
    font-size: 1.2rem;
}

.login-icon{
    position: absolute;
    top: 36px;
    left: 52vw;
}

.signIn-text{
    color: #313131;
    font-size: 1.8rem;
    font-weight: bold;
}

.signIn-desc-text{
    color: #C9C9C9;
    font-size: 1rem;
    margin-top: 2px;
    margin-bottom: 16px;
}

.email-text{
    color: #313131;
    margin-bottom: 5px;
}

.password-text{
    color: #313131;
    margin-top: 5px;
    margin-bottom: 5px;
}